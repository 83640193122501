import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { AnyModel, AnyModelStr } from 'src/app/shared/types/any-models.types';
import { EmailLog } from 'src/app/shared/types/email-log.types';
import { hasValue } from 'src/app/shared/utils';
import { AppState } from '../../reducers';
import { selectModelByType } from '../../selectors/model.selector';

@Component({
  selector: 'fp-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit, AfterViewInit {
  items: {
    dashboard: MenuItem[];
    mmDetail: MenuItem[];
    profile: MenuItem[];
  };
  @ViewChildren('detailMenu')
  public menus: QueryList<Menu>;

  private detailMenu: Menu;

  constructor(
    public navigationService: NavigationService,
    public store: Store<AppState>
  ) {}

  emailLogs$: Observable<EmailLog[]> = this.store.select(state => state.emailLogs);
  model$: Observable<AnyModel>;
  currentRoute$ = this.store.select(state => state.router);

  ngAfterViewInit() {
    let child = null;
    this.menus.changes.subscribe((comps: QueryList<Menu>) => {
      this.detailMenu = comps.first;
      if (this.detailMenu) {
        child = this.detailMenu.el.nativeElement.children[0].children[0].children[2].children[0];
      }
    });
    this.currentRoute$.subscribe(route => {
      const detailUrl = route.state.url.split('/')[2];
      if (['mentee', 'mentor', 'coach', 'lead'].includes(detailUrl)) {
        this.model$ = this.store.select(selectModelByType(detailUrl as AnyModelStr)) as Observable<AnyModel>;
        this.model$.pipe(filter(hasValue), first()).subscribe(model => {
          this.menus.notifyOnChanges();
          if (child?.children[0].children?.length === 3) {
            child.children[0].insertAdjacentHTML(
              'beforeend',
              `<span class="ml-1 p-badge p-component p-badge-no-gutter p-badge-warning">${model.unsent_email_count}</span>`
            );
          } else {
            child.children[0].children[3].textContent = model.unsent_email_count;
          }
        });
      }
    });
  }

  ngOnInit() {
    this.items = {
      dashboard: [],
      mmDetail: [],
      profile: [],
    };
    this.items.mmDetail = [];
    this.items.profile = [];

    this.items.dashboard = [
      {
        label: 'Lists',
        items: [
          {
            label: 'Leads',
            icon: 'pi pi-users',
            routerLink: `/dashboard/leads`,
            command: () => {},
          },
          {
            label: 'Mentees',
            icon: 'pi pi-users',
            routerLink: `/dashboard/mentees`,
            command: () => {},
          },
          {
            label: 'Mentors',
            icon: 'pi pi-users',
            routerLink: `/dashboard/mentors`,
            command: () => {},
          },
          {
            label: 'Coaches',
            icon: 'pi pi-users',
            routerLink: `/dashboard/coaches`,
            command: () => {},
          },
        ],
      },
      {
        label: 'Others',
        items: [
          {
            label: 'To be sent',
            icon: 'pi pi-clock',
            routerLink: `/dashboard/all-emails`,
            command: () => {},
          },
          {
            label: 'Feedbacks',
            icon: 'pi pi-check-circle',
            routerLink: `/dashboard/all-feedbacks`,
            command: () => {},
          },
          {
            label: 'Notifications',
            icon: 'pi pi-bell',
            routerLink: `/profile/notifications`,
            command: () => {},
          },
          {
            label: 'Reports',
            icon: 'pi pi-chart-line',
            routerLink: `/dashboard/reports`,
            command: () => {},
          },
          {
            label: 'Events',
            icon: 'pi pi-sparkles',
            routerLink: `/dashboard/events`,
            command: () => {},
          },
          {
            label: 'Courses',
            icon: 'pi pi-graduation-cap',
            routerLink: `/dashboard/courses`,
            command: () => {},
          },
          // {
          //   label: 'Companies',
          //   icon: 'pi pi-briefcase',
          //   routerLink: `/dashboard/companies`,
          //   command: () => {},
          // },
        ],
      },
    ];

    this.items.mmDetail = [
      {
        label: 'Details',
        items: [
          {
            label: 'Application',
            icon: 'pi pi-user-edit',
            command: () => {
              this.navigationService.detailNavigation = 'application';
            },
          },
          {
            label: 'Emails',
            icon: 'pi pi-send add-badge-content badge-class-info',
            command: () => {
              this.navigationService.detailNavigation = 'emails';
            },
          },
          {
            label: 'Change Logs',
            icon: 'pi pi-directions-alt',
            command: () => {
              this.navigationService.detailNavigation = 'change-logs';
            },
          },
          {
            label: 'Settings',
            icon: 'pi pi-cog',
            command: () => {
              this.navigationService.detailNavigation = 'settings';
            },
          },
        ],
      },
    ];

    this.items.profile = [
      {
        label: 'Profile',
        items: [
          {
            label: 'Notifications',
            icon: 'pi pi-comments',
            routerLink: '/profile/notifications',
            command: () => {},
          },
          {
            label: 'Settings',
            icon: 'pi pi-cog',
            routerLink: '/profile/settings',
            command: () => {},
          },
        ],
      },
    ];
  }

  activeMenu(event) {
    let node;
    if (event.target.tagName === 'A') {
      node = event.target;
    } else {
      node = event.target.parentNode;
    }
    const menuitem = document.getElementsByClassName('p-menuitem-link');
    for (let i = 0; i < menuitem.length; i++) {
      menuitem[i].classList.remove('active');
    }
    node.classList.add('active');
  }
}
