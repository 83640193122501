import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CompanyActions from '../actions/companies.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CompanyService } from 'src/app/shared/services/company.services';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private companyService: CompanyService
  ) {}

  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadCompanies),
      mergeMap(() =>
        this.companyService.getCompanies().pipe(
          map(companies => CompanyActions.loadCompaniesSuccess({ companies })),
          catchError(error => of(CompanyActions.loadCompaniesFailure({ error })))
        )
      )
    )
  );

  loadCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadCompany),
      mergeMap(action =>
        this.companyService.getCompany(action.companyID).pipe(
          map(company => CompanyActions.loadCompanySuccess({ company })),
          catchError(error => of(CompanyActions.loadCompanyFailure({ error })))
        )
      )
    )
  );

  createCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.createCompany),
      mergeMap(action =>
        this.companyService.createCompany(action.company).pipe(
          map(company => CompanyActions.createCompanySuccess({ company })),
          catchError(error => of(CompanyActions.createCompanyFailure({ error })))
        )
      )
    )
  );

  updateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.updateCompany),
      mergeMap(action =>
        this.companyService.updateCompany(action.companyID, action.company).pipe(
          map(company => CompanyActions.updateCompanySuccess({ company })),
          catchError(error => of(CompanyActions.updateCompanyFailure({ error })))
        )
      )
    )
  );
}
