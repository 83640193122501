import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from '../types/company.types';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  public getCompanies() {
    return this.http.get<Company[]>(environment.apiUrl + '/companies/');
  }

  public getCompany(companyID: number) {
    return this.http.get<Company>(environment.apiUrl + '/companies/' + companyID + '/');
  }

  public createCompany(company: Partial<Company>) {
    return this.http.post<Company>(environment.apiUrl + '/companies/', company + '/');
  }

  public updateCompany(companyID: number, company: Partial<Company>) {
    return this.http.patch<Company>(environment.apiUrl + '/companies/' + companyID + '/', company);
  }
}
