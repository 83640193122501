import { createReducer, on } from '@ngrx/store';
import * as CompanyActions from '../actions/companies.actions';
import { Company } from 'src/app/shared/types/company.types';

export const initialCompaniesState: Company[] = null;
export const initialCompanyState: Company = null;

export const companiesReducer = createReducer(
  initialCompaniesState,
  on(CompanyActions.loadCompaniesSuccess, (state, { companies }) => companies),
  on(CompanyActions.createCompanySuccess, (state, { company }) => [...state, company]),
  on(CompanyActions.updateCompanySuccess, (state, { company }) => state.map(c => (c.id === company.id ? company : c)))
);

export const companyReducer = createReducer(
  initialCompanyState,
  on(CompanyActions.loadCompanySuccess, (state, { company }) => company),
  on(CompanyActions.CompanySetInitial, () => initialCompanyState)
);
